import React from 'react'
import "./Propertie.css"


const Propertie = ( { icon, title, para } ) => {
    return (
        <div className='Propertie-container'>
            <div className='Propertie-icon'>
                <img src={icon} />
            </div>
            <div className='Propertie-para'>
                <h1>{title}</h1>
                <p>{para}</p>
            </div>
        </div>
    )
}

export default Propertie