import React from 'react'
import "./OurTeam.css"
import { Title } from '../index'
import { Container, Row, Col } from 'react-bootstrap'

const OurTeam = ( { dir, para, contentData, url } ) => {
    return (
        <div className='team-container'>
            <Container>
                <Row className= {`ourteam-container ${dir}`}>
                    <Col lg={6}>
                        <div className='team-img' data-aos="fade-right" data-aos-duration="2000">
                            <img src={url + contentData.our_team_image} />
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className={`team-para ${para}`} data-aos="fade-left" data-aos-duration="2000">
                            <Title allTitle={contentData.our_team_label}/>
                            <p>{contentData.our_team_description}</p>
                        </div> 
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default OurTeam
