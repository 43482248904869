import React from 'react'
import "./Categories.css"
import { Category } from "../../index"
import { Col, Container, Row } from 'react-bootstrap'

const Categories = ({ contentData, url }) => {
  return (
    <div className='categories'>
        <Container>
            <Row>
                <Col className='category-container' lg={3} data-aos="zoom-in" data-aos-duration="1800">
                    <Category img ={url + contentData.features_image1} title={contentData.features_label1} para={contentData.features_description1} />
                    <svg className='category-svg' width="316" height="3" viewBox="0 0 316 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 2L316 0.939598" stroke="#848484" stroke-dasharray="4 4"/>
                    </svg>
                </Col>
                <Col className='category-container' lg={3} data-aos="zoom-in" data-aos-duration="1800">
                    <Category img ={url + contentData.features_image2} title={contentData.features_label2} para={contentData.features_description2} />
                    <svg className='category-svg' width="316" height="3" viewBox="0 0 316 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 2L316 0.939598" stroke="#848484" stroke-dasharray="4 4"/>
                    </svg>
                </Col>
                <Col className='category-container' lg={3} data-aos="zoom-in" data-aos-duration="1800">
                    <Category img ={url + contentData.features_image3} title={contentData.features_label3} para={contentData.features_description3} />
                    <svg className='category-svg' width="316" height="3" viewBox="0 0 316 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 2L316 0.939598" stroke="#848484" stroke-dasharray="4 4"/>
                    </svg>
                </Col>
                <Col className='category-container' lg={3} data-aos="zoom-in" data-aos-duration="1800">
                    <Category img ={url + contentData.features_image4} title={contentData.features_label4} para={contentData.features_description4} />
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Categories