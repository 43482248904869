import React from 'react'
import "./InnerPage.css"
import { Container } from 'react-bootstrap'
import { Header } from '../index'

const InnerPage = ({ description, titleBold, titleTwo, image }) => {
  return (
    <div>
        <Header title={"Home /"} bold={titleBold} title2={titleTwo} img={image} />
        <Container className="my-5">
                {description}
        </Container>
    </div>
  )
}

export default InnerPage