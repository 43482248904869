import React, { useEffect, useRef } from 'react'
import "./Hero.css"

const Hero = ({ contentData, innerPageData, url }) => {
  
    const videoEl = useRef(null);

    const attemptPlay = () => {
        videoEl &&
          videoEl.current &&
          videoEl.current.play().catch(error => {
            console.error("Error attempting to play", error);
          });
      };
    
      useEffect(() => {
        attemptPlay();
      }, []);

    return (
        <div className="hero">
            <video src={url + innerPageData.image_or_video} loop muted autoPlay ref={videoEl} />
            <div className="hero-content">
                <h2>{contentData.homepage_hero_title1}</h2>
                <h1 dangerouslySetInnerHTML={{ __html: contentData.homepage_hero_title2 }}></h1>
                <p>{contentData.homepage_hero_description1}</p>
                <a href={contentData.homepage_hero_button1_link}>{contentData.homepage_hero_button1_label}</a>
            </div>
        </div>
    )
}

export default Hero;