import React from 'react';
import "./Title.css";

const Title = ({ allTitle }) => {
  const extractStrings = (inputString) => {
    const words = inputString.split(' ');
    const firstTitle = words.slice(0, -1).join(' ');
    const lastTitle = words[words.length - 1];
    return { firstTitle, lastTitle };
  };
  const { firstTitle, lastTitle } = extractStrings(`${allTitle}`);

  return (
    <div className="logo2">
      <h2>{firstTitle} <span>{lastTitle}</span></h2>
    </div>
  );
};

export default Title;