import React from 'react'
import "./About.css"
import { Col, Container, Row } from 'react-bootstrap'
import { Counter } from '../../../../Components/index'

const About = ({ contentData, url }) => {
  return (
    <Container className='about-home'>
        <Row className='about about-home-content'>
            <Col lg={6}>
                <div className='about-container'>
                    <div className='about-content'>
                        <div className='about-title' data-aos="fade-left" data-aos-duration="1300">
                            <img src={url + contentData.about_us_icon} />
                            <h1 className='about-title-h'>{contentData.about_us_title}</h1>
                        </div>
                        <h1 className='about-h' data-aos="fade-left" data-aos-duration="1500">{contentData.about_us_header}</h1>
                        <p className='about-p' data-aos="fade-left" data-aos-duration="1800">{contentData.about_us_description1}</p>
                        <div className='about-counter' data-aos="fade-left" data-aos-duration="2000">
                            <Counter title={contentData.about_us_number_1_title} count={contentData.about_us_number_1} />
                            <Counter title={contentData.about_us_number_2_title} count={contentData.about_us_number_2} />
                            <Counter title={contentData.about_us_number_3_title} count={contentData.about_us_number_3} />
                        </div>
                        <p className='about-p' data-aos="fade-left" data-aos-duration="2200">{contentData.about_us_description2}</p>
                    </div>
                </div>
            </Col>
            <Col lg={6}>
                <img className='about-img' src={url + contentData.about_us_image} />
            </Col>
        </Row>
    </Container>
  )
}

export default About