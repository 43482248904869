import React from 'react'
import "./Counter.css"
import CountUp from "react-countup";

const Counter = ( { title, count }) => {
  return (
    <div className='counter-container'>
        <div className='counter'>
            +
            <CountUp
                start={0}
                end={count}
                duration={5}
                onEnd={({start}) => start()}
            />
        </div>
        <h1 className='counter-h'>{title}</h1>
    </div>
  )
}

export default Counter