import React from 'react'
import "./Properties.css"
import { Propertie } from '../../index'
import { Col, Container, Row } from 'react-bootstrap'


const Properties = ({ contentData, url }) => {

    
    return (
        <div className='properties-container'>
            <Container>
                <Row>
                    <Col lg={4} data-aos="zoom-in" data-aos-duration="2000">
                        <Propertie icon={url + contentData.homepage_hero_image1} title={contentData.homepage_hero_feature_title1} para={contentData.homepage_hero_feature_description1} />
                    </Col>
                    <Col lg={4} data-aos="zoom-in" data-aos-duration="2000">
                        <Propertie icon={url + contentData.homepage_hero_image2} title={contentData.homepage_hero_feature_title2} para={contentData.homepage_hero_feature_description2} />
                    </Col>
                    <Col lg={4} data-aos="zoom-in" data-aos-duration="2000">
                        <Propertie icon={url + contentData.homepage_hero_image3} title={contentData.homepage_hero_feature_title3} para={contentData.homepage_hero_feature_description3} />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Properties
