import React from 'react'
import "./Category.css"

const Category = ( { title, img, para} ) => {
  return (
    <div className='category'>
        <div className='category-image'>
            <img className='category-img' src={img} />
        </div>
        <h1 className='category-h'>{title}</h1>
        <p className='category-p'>{para}</p>
    </div>
  )
}

export default Category