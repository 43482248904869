import React from 'react'
import "./OurMission.css"
import { Title } from '../../../../Components/index'
import { Container, Row, Col } from 'react-bootstrap'

 const OurMission = ({ contentData, url }) => {



  return (
    <div className='mission-container'>
        <Container>
            <Row>
                <Col lg={6}>
                    <div className='mission-para' data-aos="fade-right" data-aos-duration="2000">
                        <Title allTitle={contentData.our_mission_label} />
                        <p>{contentData.our_mission_description}</p>
                    </div>
                </Col>
                <Col lg={6}>
                    <div className='mission-img' data-aos="fade-left" data-aos-duration="2000">
                        <img src={url + contentData.our_mission_image} />
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
    
  )
}

export default OurMission