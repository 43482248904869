import React from 'react'
import "./Footer.css"
import logo from "../../asset/images/logo.svg"
import { Link } from "react-router-dom"

const Footer = ({ footerData, logos, url, translationsData, contentData }) => {

    const socialmedia = footerData.social_media || [];
    return (
        <footer>
              <div className="container">
                  <div className="footer-content">
                      <div className="footer-text" id="footer-logo">
                      <img className="logo" src={url + logos.logo_big} />
                          <p>{footerData.footer_short_description}</p>
                      </div>
                      <div className="footer-text" id="footer-links">
                          <h3 className="text-uppercase">{translationsData.learn_more}</h3>
                          <a href="about">{translationsData.about_us}</a>
                          <a href="contact">{translationsData.contact_us}</a>
                      </div>
                      <div className="footer-text">
                          <h3 className="">{translationsData.services}</h3>
                          {/* <p className="">{translationsData.our_mission}</p> */}
                          <p className="">{translationsData.our_services}</p>
                          <Link to={{pathname: '/doctors'}}> 
                            <p calassname="">{translationsData.our_team}</p>
                          </Link>
                      </div>
                      <div className="footer-text">
                          <h3 className="text-uppercase">{translationsData.support}</h3>
                          <p><a href="#">{translationsData.terms_and_conditions}</a></p>
                          <p><a href='/privacy'>{translationsData.privacy_policy}</a></p>
                          <p><a href='/rights'>{translationsData.rights_and_responsibilities}</a></p>
                      </div>
                      <div className="footer-text" id="footer-touch">
                          <h3 className="text-uppercase">{translationsData.get_in_touch}</h3>
                          <div className="footer-touch-text"><img src={url + footerData.footer_location_icon} /> <p>{footerData.contact_us_location_description}</p></div>
                          <div className="footer-touch-text"><img src={url + footerData.footer_phone_icon} />  <p>{footerData.contact_us_phone_description}</p></div>
                          <h3>{translationsData.our_working_hours}</h3>
                          <div className='hour-working'>
                            <img src={url + footerData.footer_hours_icon} />
                            <p>{footerData.contact_us_hours_description}</p>
                          </div>
                      </div>
                  </div>
                  <div className="footer-down">
                      <div className="footer-links">
                          <div className="links-text">
                                 <p><a href="">{translationsData.privacy_policy}</a></p>
                                 <p><a href="">{translationsData.terms_and_conditions}</a></p>
                          </div>
  
                          <div className="links">
                              {
                                socialmedia.map((item, index) => (
                                    <a href={item.url} target="_blank"><img src={url + item.image} /></a>
                                ))
                              }
                          </div>
                      </div>
                      <div className="footer-down-para">
                          <p>{footerData.footer_long_description}</p>
                            <p>{translationsData.copyright} <span>{translationsData.specialist_medical_center}</span></p>
                      </div>
                  </div>
              </div>
          </footer>
    )
}

export default Footer;