import React from 'react'
import "./Clients.css"
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/css';
import { Container } from 'react-bootstrap';
import { Title } from '../../../../Components';

const Clients = ({ contentData, url }) => {

    const clientsData = contentData.clients || [];

    return (
        <div className='clients'>
            <Container className='section-margin section-border'>
                <Title allTitle={contentData.clients_label} />
            </Container>
            <div className='clients-slider'>
                <Splide
                    options={{
                        type: "loop",
                        arrows: true,
                        pagination: false,
                        autoplay: true,
                        interval: '3500',
                        speed: '2000',
                        perPage: 7,
                        breakpoints: {
                            992: {
                                perPage: 5,
                            },
                            768: {
                                perPage: 4,
                            },
                            576: {
                                perPage: 3,
                            },
                            476: {
                                perPage: 2,
                            },
                        },
                    }}
                >
                        {clientsData.map((item, index) => (
                            <SplideSlide key={index}>
                                <div className='clients-slide'>
                                    <img className='clients-img' src={url + item.image} />
                                </div>
                            </SplideSlide>
                        ))}
                </Splide>
            </div>
        </div>
    )
}

export default Clients
