import React, { useState, useEffect } from 'react';
import './ServiceDetails.css';
import { Header } from '../../Components/index';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { SplideSlide, Splide } from '@splidejs/react-splide';
import { Service } from '../../Components/index';
import serviceImage from '../../asset/images/Home/300x240-0 1.png';

const ServiceDetails = ({ url, innerPageData, contentData, apiVersion, options, translationsData }) => {
  const [servicesData, setServicesData] = useState([]);

  const fetchData = async () => {
    const apiURL = `${url}${apiVersion}/services`;

    try {
      const response = await axios.get(apiURL, options);
      const servicesData = response?.data?.data || [];
      setServicesData(servicesData);
    } catch (error) {
      console.log('Error fetching services:', error);
      // Handle the error condition accordingly
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className='services-details'>
      <Header
        title="Home"
        bold="/ Services "
        title2={innerPageData.name}
        img={url + innerPageData.image_or_video}
      />
      <Container className="details-container mb-5">
        <Row>
          <Col lg={6}>
            <h1 className="details-title">{contentData.name}</h1>
            <ul
              className="details-list"
              dangerouslySetInnerHTML={{ __html: contentData.description }}
            ></ul>
          </Col>
          <Col lg={1}></Col>
          <Col lg={5}>
            <div href="" className="details-image">
              <img
                className="details-img"
                src={
                  contentData.image
                    ? url + contentData.image
                    : serviceImage
                }
                alt="Service Cover"
              />
            </div>
          </Col>
        </Row>
      </Container>
      <div className="services-slider">
        <Splide
          options={{
            rewind: true,
            gap: '2rem',
            perPage: 4,
            arrows: true,
            breakpoints: {
              1070: {
                perPage: 3,
              },
              880: {
                perPage: 2,
              },
              600: {
                perPage: 1,
              },
            },
          }}
        >
          {servicesData.map((service, index) => (
            <SplideSlide key={index}>
              <a href={`/service/${service.id}`}>
                <Service
                  image={
                    service.image
                      ? url + service.image
                      : serviceImage
                  }
                  para={service.name}
                />
              </a>
            </SplideSlide>
          ))}
        </Splide>
      </div>
       <div className='all-services mb-5'>
            <a href="/services">{translationsData.our_services}</a>
        </div>
    </div>
  );
};

export default ServiceDetails;