import React, { useState } from 'react';
import "./Gallery.css";
import { Title } from "../../../../Components/index";
import { Container, Row, Col, Modal, Carousel } from 'react-bootstrap';

const Gallery = ({ innerPageData, translationsData, url }) => {
  const galleryImages = innerPageData?.gallery || [];
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (index) => {
    setSelectedImage(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  const handlePrev = () => {
    setSelectedImage((prevImage) => {
      if (prevImage === null || prevImage === 0) {
        return galleryImages.length - 1;
      } else {
        return prevImage - 1;
      }
    });
  };

  const handleNext = () => {
    setSelectedImage((prevImage) => {
      if (prevImage === null || prevImage === galleryImages.length - 1) {
        return 0;
      } else {
        return prevImage + 1;
      }
    });
  };

  return (
    <div className='gallery'>
      <Container className='gallery-title'>
        <Title allTitle={translationsData.our_gallery} />
        <a className='show-all'>
          <p className='show-all-p'>{translationsData.show_all_images}</p>
          <svg width="39" height="8" viewBox="0 0 39 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M38.4062 4.35355C38.6014 4.15829 38.6014 3.84171 38.4062 3.64645L35.2242 0.464466C35.0289 0.269204 34.7123 0.269204 34.5171 0.464466C34.3218 0.659728 34.3218 0.976311 34.5171 1.17157L37.3455 4L34.5171 6.82843C34.3218 7.02369 34.3218 7.34027 34.5171 7.53553C34.7123 7.7308 35.0289 7.7308 35.2242 7.53553L38.4062 4.35355ZM0 4.5H38.0526V3.5L0 3.5L0 4.5Z" fill="#26C0D3"/>
            </svg>
        </a>
      </Container>
      <Row>
        {galleryImages.map((item, index) => (
          <Col key={index} md={4} className='gallery-image' data-aos="zoom-in" data-aos-duration="1800">
            <img
              className="gallery-img"
              src={url + item.image}
              alt={item.alt}
              onClick={() => openModal(index)}
            />
          </Col>
        ))}
      </Row>
      <a className='show-all-responsive'>{translationsData.show_all_images}</a>

      <Modal show={isModalOpen} onHide={closeModal} centered>
        <Modal.Body>
          <Carousel
            activeIndex={selectedImage}
            prevIcon={<span className="carousel-arrow" onClick={handlePrev}>&#8249;</span>}
            nextIcon={<span className="carousel-arrow" onClick={handleNext}>&#8250;</span>}
            fade
            interval={null}
            className="modal-carousel"
          >
            {galleryImages.map((item, index) => (
              <Carousel.Item key={index}>
                <img
                  className="modal-image"
                  src={url + item.image}
                  alt={item.alt}
                />
                <Carousel.Caption>
                  <h3>{item.caption}</h3>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Gallery;