import React from 'react'
import "./Header.css"
import { Container } from 'react-bootstrap'

const Header = ( { img, title, bold, title2 }) => {
  return (
    <div className='header'>
        <img className='header-img' src={img} />
        <Container>
            <h1 data-aos="fade-right" data-aos-duration="1500" className='header-title'>{title} <span>{bold}</span></h1>
            <h1 data-aos="fade-right" data-aos-duration="2000" className='header-title-two'>{title2}</h1>
        </Container>
    </div>
  )
}

export default Header