import React, { useState, useEffect } from "react";
import { Navbar, Container, NavDropdown, Nav, Dropdown } from "react-bootstrap";
import "./NavPage.css";
import Cookies from 'js-cookie';

const NavPage = ({ navbarData, logos, url, selectedLanguage, setSelectedLanguage }) => {
  const logoBig = url + logos.logo_big;
  const logoSmall = url + logos.logo_small;

  const [scrollPosition, setScrollPosition] = useState(0);
  const [navbarClass, setNavbarClass] = useState("");
  const [logoSrc, setLogoSrc] = useState(logoBig);
  const [logoLoaded, setLogoLoaded] = useState(false);

  const updateNavbar = () => {
    const windowUrl = window.location.pathname;
    if (windowUrl === "/") {
      if (scrollPosition > 30) {
        setNavbarClass("nav-pages scrolled");
        setLogoSrc(logoSmall);
      } else {
        setNavbarClass("nav-home");
        setLogoSrc(scrollPosition < 30 ? logoBig : logoSmall);
      }
    } else {
      setNavbarClass("nav-pages");
      setLogoSrc(logoSmall);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };

    const handleLocationChange = () => {
      updateNavbar();
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("popstate", handleLocationChange);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("popstate", handleLocationChange);
    };
  }, []);

  const renderNavLinks = (items) => {
    return items.map((item) => {
      if (item.children && item.children.length > 0) {
        return (
          <NavDropdown title={item.name} id={`nav-dropdown-${item.id}`} key={item.id}>
            {item.children.map((child) => (
              <NavDropdown.Item key={child.id} href={child.url}>
                {child.name}
              </NavDropdown.Item>
            ))}
          </NavDropdown>
          
        );
      } else {
        return (
          <Nav.Link key={item.id} href={item.url}>
            {item.name}
          </Nav.Link>
        );
      }
    });
  };

  useEffect(() => {
    updateNavbar();
  }, [scrollPosition]);


  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    // Store selected language in cookies
    Cookies.set('selectedLanguage', language);
    // Reload the page
    window.location.reload();
  };

  useEffect(() => {
    // Read selected language from cookies when component mounts
    const selectedLanguageValue = Cookies.get('selectedLanguage');
    if (selectedLanguageValue) {
      setSelectedLanguage(selectedLanguageValue);
    }
  }, []);

  return (
    <Navbar expand="lg" className={`${navbarClass}`}>
      <Container>
        <Navbar.Brand href="/">
          <img
            className="logo"
            src={logoSrc}
            alt="Logo"
            onLoad={() => setLogoLoaded(true)}
            style={{ display: logoLoaded ? "block" : "none" }}
          />
          {!logoLoaded && <div className="logo-placeholder" />}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="nav-btn" id="basic-navbar-nav">
          <Nav className="">{renderNavLinks(navbarData)}</Nav>
          <Dropdown className='nav-language'>
              <Dropdown.Toggle id="" >
                {selectedLanguage === 'ar' ? 'عربي' : selectedLanguage}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleLanguageChange('en')}>EN</Dropdown.Item>
                <Dropdown.Item onClick={() => handleLanguageChange('ar')}>عربي</Dropdown.Item>
              </Dropdown.Menu>
          </Dropdown>
        </Navbar.Collapse>
      
      </Container>
    </Navbar>
  );
};

export default NavPage;