import React from 'react'
import "./AboutUs.css"
import { Header, Team, OurTeam } from "../../Components/index"
import { About, Categories, Gallery, Branches } from "../../Features/AboutUs/index" 

const AboutUs = ({ contentData, url, innerPageData, translationsData }) => {
  return (
    <div>
        <Header title={`${translationsData.home} /`} bold={translationsData.about_us} title2={innerPageData.name} img={url + innerPageData.image_or_video} />
        <About contentData={contentData} url={url}/>
        <Categories contentData={contentData} url={url} />
        <Gallery translationsData={translationsData} innerPageData={innerPageData} url={url}/>
        <OurTeam dir="flex-lg-row-reverse" para="team-para-about" contentData={contentData} url={url} />
        <Team contentData={contentData} url={url} />
        <Branches contentData={contentData} url={url} translationsData={translationsData} />
    </div>
  )
}

export default AboutUs