import { useEffect, useState } from 'react';
import './App.css';
import Cookies from 'js-cookie';
import { Home, ContactUs, AboutUs, Services, ServiceDetails, Procedures, Rights, Privacy, Doctors } from "./Pages/index";
import { NavPage, Footer } from "./Layouts/index";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

function App() {
  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);
  const [loading, setLoading] = useState(true);
  const url = "https://backend.alhodacare.com";
  const apiVersion = "/api/v1";
  const limit = 4;
  const pageUrl = window.location.pathname;
  const lastWordIndex = pageUrl.lastIndexOf("/") + 1;
  let pageName = pageUrl.substr(lastWordIndex);

  const [navbarData, setNavbarData] = useState([]);
  const [footerData, setFooterData] = useState({});
  const [logos, setLogos] = useState({});
  const [contentData, setContentData] = useState({});
  const [innerPageData, setInnerPageData] = useState({});
  const [translationsData, setTranslationsData] = useState({});
  const [seoSettingsData, setSeoSettingsData] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState('en');



  const options = {
    headers: {
      'Accept-Language': Cookies.get('selectedLanguage'),
    },
  };
  useEffect(() => {
    const fetchData = async (pageName) => {
      try {
        setLoading(true);
        const apiUrl = `${url}${apiVersion}/content-by-page/${pageName}`;
        const response = await fetch(apiUrl, options);
        const data = await response.json();
        const navbar = data?.data?.navbar?.data || [];
        const footer = data?.data?.footer || "";
        const content = data?.data?.content || "";
        const innerPage = data?.data?.inner_page || "";
        const translations = data?.data?.translations || "";
        const seoSettings = data?.data?.['seo-settings'] || "";
        setNavbarData(navbar);
        setFooterData(footer);
        setContentData(content);
        setInnerPageData(innerPage);
        setTranslationsData(translations);
        setSeoSettingsData(seoSettings);
        setLogos(data?.data?.logos || "");
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    if (["", "/", "home"].includes(pageName)) {
      pageName = "home";
    }
    else if ("services".includes(pageName)) {

      pageName += `?start=0&limit=${limit}`;
    }
    else if (pageUrl.includes("service/")) {
      let serviceId = pageUrl.substr(lastWordIndex);
      pageName = `services?service_id=${serviceId}`;
    }

    fetchData(pageName);
  }, []);



  useEffect(() => {
    const updateFavicon = () => {
      const link = document.querySelector('link[rel="icon"]');
      link.setAttribute('href', `${url + logos.logo_favicon}`);
    };
  
    const updateTitle = () => {
      const mainTitle = `${seoSettingsData?.main_title || ''} ${seoSettingsData?.separator || ''} ${innerPageData?.name || ''}`;
      const formattedTitle = mainTitle.trim() || 'Al Huda Care';
      if (formattedTitle == undefined)
        document.title = "Al Huda Care";
      else{
        document.title = formattedTitle;
      }
      
    };
  
    updateFavicon();
    updateTitle();
  }, [logos, pageName, seoSettingsData, innerPageData]);


  useEffect(() => {
    const updateLanguage = () => {
      const htmlElement = document.documentElement;
      if (selectedLanguage == 'ar') {
        htmlElement.setAttribute('dir', 'rtl');
        import('./arabic.css')
          .then(() => {
            // CSS file has been successfully imported
          })
          .catch((error) => {
            console.log('Failed to import Arabic CSS file:', error);
          });
      } else {
        htmlElement.setAttribute('dir', 'ltr');
      }
    };
    updateLanguage();
  }, [selectedLanguage]);


  return (
    <div className="app">
      <BrowserRouter>
      {loading ?  (
          <div class="sunderland-loading">
             <h2 className='loading-letters'>
                <span>L</span>
                <span>o</span>
                <span>a</span>
                <span>d</span>
                <span>i</span>
                <span>n</span>
                <span>g</span>
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </h2>
          </div>
        ) : (
        <>
          {logos.logo_big && (
          <NavPage
            navbarData={navbarData}
            logos={logos}
            url={url}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
          />
        )}
        <Routes>
          <Route index element={<Home contentData={contentData} innerPageData={innerPageData} url={url} />} />
          <Route path="/contact" element={<ContactUs contentData = {contentData} url = {url} />}   />
          <Route path="/about" element={<AboutUs contentData={contentData} innerPageData={innerPageData} url={url} translationsData={translationsData} />} />
          <Route path="/services" element={<Services options={options} url={url} contentData={contentData} limit={limit} apiVersion={apiVersion} innerPageData={innerPageData} translationsData={translationsData}  />} />
          <Route path="/service/:id" element={<ServiceDetails url={url} apiVersion={apiVersion} innerPageData={innerPageData} contentData={contentData} options={options} translationsData={translationsData} />} />
          <Route path="/procedures" element={<Procedures contentData={contentData} innerPageData={innerPageData} url={url} />} />
          <Route path="/rights" element={<Rights contentData={contentData} innerPageData={innerPageData} url={url} />} />
          <Route path="/privacy" element={<Privacy contentData={contentData} innerPageData={innerPageData} url={url} />} />
          <Route path='/doctors' element={<Doctors contentData={contentData} url={url} innerPageData={innerPageData} />} />
        </Routes>
        <Footer footerData={footerData} logos={logos} url={url} translationsData={translationsData} contentData={contentData} />

        </>
      )}
      </BrowserRouter>
    </div>
  );
}

export default App;