import React from 'react'
import "./Home.css"
import { Hero, Properties, OurMission, Services, Excellence, ServicesType, About, Clients } from '../../Features/Home/index'
import { Contact, OurTeam, Team } from "../../Components/index"



export const Home = ({ contentData, innerPageData, url }) => {
    return (
        <div className='home'>
            <Hero contentData = {contentData} innerPageData = {innerPageData} url = {url} />
            <Properties contentData = {contentData} url = {url} />
            <OurMission contentData = {contentData} url = {url} />
            <Services contentData = {contentData} url = {url} />
            <ServicesType contentData = {contentData} url = {url} /> 
            <Excellence contentData = {contentData} url = {url} />
            <OurTeam contentData = {contentData} url = {url} />
            <About contentData= {contentData} url = {url} />
            <Team contentData = {contentData} url = {url}/>
            <Clients contentData = {contentData} url = {url} />
            <Contact contentData = {contentData} url = {url} />
        </div>
    )
}


export default Home;