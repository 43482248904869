import React from 'react'
import "./Branches.css"
import { Title } from '../../../../Components/index'
import { Container } from 'react-bootstrap'

const Branches = ({ contentData, url, translationsData }) => {
  return (
    <div className='branches'>
        <Container className='branches-container'>
            <Title allTitle={translationsData.our_branches} />
            <h1 data-aos="fade-right" data-aos-duration="1500" className='branches-h'>{contentData.contact_us_description}</h1>
            <div className="contact-info" data-aos="fade-right" data-aos-duration="1800">
                <img src={url + contentData.contact_us_location_icon} />
                <p>{contentData.contact_us_location_description}</p>
            </div>
            <div className="contact-info" data-aos="fade-right" data-aos-duration="2000">
                <img src={url + contentData.contact_us_phone_icon} />
                <p>{contentData.contact_us_phone_description}</p>
            </div>
            <div className="contact-info" data-aos="fade-right" data-aos-duration="2200">
                <img src={url + contentData.contact_us_hours_icon} />
                <p>{contentData.contact_us_hours_description}</p>
            </div>
        </Container>
        <iframe className='branches-map' src={contentData.contact_us_map_large_url} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  )
}

export default Branches