import React from 'react'
import "./Services.css"
import { Container } from 'react-bootstrap'
import { Title, Service } from '../../../../Components/index'
// import { Link } from 'react-router-dom';
import { SplideSlide,Splide } from '@splidejs/react-splide'

const Services = ({ contentData, url }) => {
    const services = contentData.services || [];

  return (
    <div className='services-container'>
        <Container>
            <Title allTitle={contentData.our_services_label} />
            <h3>{contentData.our_services_description}</h3>
        </Container>

        <div className='services-slider'>
            <Splide
                options={{
                    rewind: true,
                    gap: '2rem',
                    perPage: 4,
                    arrows: true,
                    breakpoints: {
                        1070: {
                            perPage: 3,
                        },
                        880: {
                            perPage: 2,
                        },
                        600: {
                            perPage: 1,
                        }
                    },
                }}
            >

            {services.map((service, index) => (
                <SplideSlide key={index}>
                    <a href={`/service/${service.id}`}>
                        <Service
                        image={
                            service.image
                            ? url + service.image
                            : require("../../../../asset/images/Home/300x240-0 1.png")
                        }
                        para={service.name}
                        />
                    </a>
                </SplideSlide>
            ))}
            
            </Splide>
            
        </div> 
        <div className='all-services'>
            <a href="/services">{contentData.our_services_button_label}</a>
        </div>

    </div>
  )
}

export default Services