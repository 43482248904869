import React from 'react'
import "./Team.css"
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/css';
import { Container } from 'react-bootstrap'
import { Title } from '../index'

const Team = ({ contentData, url }) => {

    const ourTeam = contentData.our_team || [];
    return (
        <div className='team'>
            <div className='ourteam'>
                <Splide
                    options={{
                        type: "loop",
                        arrows: false,
                        pagination: false,
                        autoplay: true,
                        interval: '3500',
                        speed: '2000',
                        perPage: 4,
                        breakpoints: {
                            1070: {
                                perPage: 3,
                            },
                            880: {
                                perPage: 2,
                            },
                            600: {
                                perPage: 1,
                            }
                        },
                    }}
            
                    >
                    {ourTeam.map((team, index) => (
                        <SplideSlide key={index}>
                            <div className='team-slide'>
                                <img src={url + team.image} />
                                <h1>DR. {team.name}</h1>
                                <h2>{team.description}</h2>
                            </div>
                        </SplideSlide>
                    ))}
                </Splide>
            </div>
        </div>
    )
}

export default Team