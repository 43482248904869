import React from 'react'
import "./Excellence.css"
import { Col, Container, Row } from 'react-bootstrap'

const Excellence = ({ contentData, url }) => {
  return (
    <div className='Excellence-container'>
        <img id='team' src={url + contentData.features_background} data-aos="fade-left" data-aos-duration="2000" />
        <img id='sharp' src={url + contentData.features_background_style} />
        <Container>
            <Row>
                
                <Col lg={6} className='excellence-line'>
                    <div className='excellence'>
                        <div className='excellence-icon'>
                            <img src={url + contentData.features_image1} />
                        </div>
                        <div className='excellence-content' data-aos="fade-right" data-aos-duration="1500">
                            <div className='excellence-title'>
                                {contentData.features_label1}
                            </div>
                            <div className='excellence-para'>
                                {contentData.features_description1}
                            </div>
                        </div>
                    </div>
                    <div className='excellence'>
                        <div className='excellence-icon'>
                            <img src={url + contentData.features_image2} />
                        </div>
                        <div className='excellence-content' data-aos="fade-right" data-aos-duration="1500">
                            <div className='excellence-title'>
                                {contentData.features_label2}
                            </div>
                            <div className='excellence-para'>
                                {contentData.features_description2}
                            </div>
                        </div>
                    </div>
                    <div className='excellence'>
                        <div className='excellence-icon'>
                            <img src={url + contentData.features_image3} />
                        </div>
                        <div className='excellence-content' data-aos="fade-right" data-aos-duration="1500">
                            <div className='excellence-title'>
                                {contentData.features_label3}
                            </div>
                            <div className='excellence-para'>
                                {contentData.features_description3}
                            </div>
                        </div>
                    </div>
                    <div className='excellence'>
                        <div className='excellence-icon'>
                            <img src={url + contentData.features_image4} />
                        </div>
                        <div className='excellence-content' data-aos="fade-right" data-aos-duration="1500">
                            <div className='excellence-title'>
                                {contentData.features_label4}
                            </div>
                            <div className='excellence-para'>
                                {contentData.features_description4}
                            </div>
                        </div>
                    </div>
                </Col>
                
            
            </Row>
        </Container>
    </div>
  )
}

export default Excellence