import React from 'react'
import "./Privacy.css"
import { InnerPage } from '../../Components/index';

const HTMLRenderer = ({ htmlString }) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
};

const Privacy = ({ contentData, url, innerPageData }) => {
  return (
    <div>
      <InnerPage 
        description={<HTMLRenderer htmlString={innerPageData.description} />} 
        titleBold={innerPageData.name} 
        titleTwo={innerPageData.name}
        image={url + innerPageData.image_or_video} 
      />
    </div>
  );
};

export default Privacy;