import React from 'react'
import "./ContactUs.css"
import { Contact } from "../../Components/index"

const ContactUs = ({ contentData, url }) => {
  return (
    <div className='contact-page'>
      <Contact contentData = {contentData} url = {url} />
    </div>
  )
}

export default ContactUs