import React from "react";
import { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import "yup-phone-lite";
import "./Contact.css";
import { Title } from "../index";
import axios from 'axios';
import { Container } from "react-bootstrap";

const Contact = ({ contentData, url }) => {


  //Add Border Top Class
  var windowUrl = window.location.pathname;
  const [bordertop, setbordertop] = useState('');
  useEffect(() => {
    if( windowUrl == "/") {
      setbordertop("section-border");
    }
   }, [])

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await axios.post(
        'http://alhodacare2.inter-netsys.com/api/addContact',
        values,
        {
          headers: {
            'X-CSRFToken': values._csrf,
            'Content-Type': 'application/json'
          }
        }
      );

      console.log(response.data);
      setIsSubmitted(true);
      setSubmitting(false);
      resetForm();
    } catch (error) {
      console.error(error);
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required('Full Name is required'),
    company_name: Yup.string().required('Company Name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    number: Yup.string()
    .matches(/^\+?[1-9]\d{1,14}$/, 'Invalid phone number format')
    .required('Phone number is required'),
    message: Yup.string().required('The Message is required'),
  });

  return (
    <Container className={`section-margin ${bordertop}`}>
      <div className="contact" id="contact">
        <div className="contact-content">
          <Title allTitle={contentData.contact_us_label} />
          <h1>{contentData.contact_us_description}</h1>
          <div className="contact-info">
            <img src={url + contentData.contact_us_location_icon} />
            <p>{contentData.contact_us_location_description}</p>
          </div>
          <div className="contact-info">
            <img src={url + contentData.contact_us_phone_icon} />
            <p>{contentData.contact_us_phone_description}</p>
          </div>
          <div className="contact-info">
            <img src={url + contentData.contact_us_hours_icon} />
            <p>{contentData.contact_us_hours_description}</p>
          </div>
          <div className="contact-img">
          <iframe src={contentData.contact_us_map_url} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
        <div className="contact-form" id="contact-form">
          <div>
          {isSubmitted ? (
            <div className="submitted" data-aos="fade-up" data-aos-duration="1500">
                <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" height="50px"><path d="M 25 2 C 12.317 2 2 12.317 2 25 C 2 37.683 12.317 48 25 48 C 37.683 48 48 37.683 48 25 C 48 20.44 46.660281 16.189328 44.363281 12.611328 L 42.994141 14.228516 C 44.889141 17.382516 46 21.06 46 25 C 46 36.579 36.579 46 25 46 C 13.421 46 4 36.579 4 25 C 4 13.421 13.421 4 25 4 C 30.443 4 35.393906 6.0997656 39.128906 9.5097656 L 40.4375 7.9648438 C 36.3525 4.2598437 30.935 2 25 2 z M 43.236328 7.7539062 L 23.914062 30.554688 L 15.78125 22.96875 L 14.417969 24.431641 L 24.083984 33.447266 L 44.763672 9.046875 L 43.236328 7.7539062 z"/></svg>
                <p>Thanks for your submission! We'll get back to you soon with feedback.</p>
            </div>
          ) : (
          <Formik
            initialValues={{
              full_name: '',
              company_name: '',
              email: '',
              number: '',
              message: '',
              _csrf: '' // CSRF token
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="formGroup" style={{ opacity: isSubmitting ? 0.5 : 1 }} >
                <div
                  className="fullname"
                  data-aos="fade-up"
                  data-aos-duration="700"
                >
                  <p>
                    Full Name<span>*</span>
                  </p>
                  <Field
                    type="text"
                    name="full_name"
                    id="full_name"
                  />
                  <ErrorMessage name="full_name" component="div" className="error-message" />
                </div>
                <div
                  className="company-name"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <p>
                    Company Name<span>*</span>
                  </p>
                  <Field
                    type="text"
                    name="company_name"
                    id="company_name"
                  />
                  <ErrorMessage name="company_name" component="div" className="error-message" />
                </div>
                <div
                  className="email"
                  data-aos="fade-up"
                  data-aos-duration="1300"
                >
                  <p>
                    Email<span>*</span>
                  </p>
                  <Field
                    type="text"
                    name="email"
                    id="email"
                  />
                  <ErrorMessage name="email" component="div" className="error-message" />
                </div>
                <div
                  className="phonenumber"
                  data-aos="fade-up"
                  data-aos-duration="1700"
                >
                  <p>
                    Phone Number<span>*</span>
                  </p>
                  <div className="phone">
                    <select>
                      <option>+966</option>
                    </select>
                    <Field
                      type="text"
                      id="number"
                      name="number"
                    />
                  </div>
                  <ErrorMessage name="number" component="div" className="error-message" />
                </div>
                <div
                  className="message"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <p>Your Message</p>
                  <Field
                    as="textarea"
                    name="message"
                    id="message"
                  />
                  <ErrorMessage name="message" component="div" className="error-message" />
                </div>
                <div data-aos="fade-up" data-aos-duration="2200">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    
                  >
                    {isSubmitting ? 'Sending...' : 'Send Message'}
                  </button>
                </div>
              </Form>
            )}
            </Formik>
          )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Contact;
