import React, { useState, useEffect } from 'react';
import "./Services.css"
import { Link } from 'react-router-dom';
import { Header, Service, Paginations } from '../../Components/index';
import { Row, Col, Container } from 'react-bootstrap';
import axios from 'axios';

const Services = ({ innerPageData, contentData, url, limit, apiVersion, translationsData, options }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [servicesData, setServicesData] = useState([]);

  useEffect(() => {
    if (contentData) {
      const currentPage = contentData?.pagination?.metadata?.currentPage || 1;
      const totalPages = contentData?.pagination?.metadata?.totalPages || 0;
      const servicesData = contentData?.data || [];

      setCurrentPage(currentPage);
      setTotalPages(totalPages);
      setServicesData(servicesData);
    } else {
      fetchDataByPage(currentPage);
    }
  }, [contentData]);

  const fetchDataByPage = async (page) => {
    const startIndex = (page - 1) * limit;
    const apiURL = `${url}${apiVersion}/content-by-page/services?start=${startIndex}&limit=${limit}`;

    try {
      const response = await axios.get(apiURL, options);
      const { data, pagination } = response?.data?.data?.content;
      setServicesData(data);
      setTotalPages(pagination?.metadata?.totalPages);
    } catch (error) {
      console.log('Error fetching services:', error);
      // Handle the error condition accordingly
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchDataByPage(page);
  };
  return (
    <div>
      <Header
        title={`${translationsData?.home} /`}
        bold={translationsData?.services}
        title2={innerPageData.name}
        img={url + innerPageData.image_or_video}
      />

      <Container className="all-services-container">
        <div className="our-services-note"  dangerouslySetInnerHTML={{ __html: innerPageData.description}}>
        </div>
        <Row>
          {servicesData.map((service) => (
            <Col lg={3} md={4} sm={6} className="service-container" key={service.id}>
              <a
                href={`/service/${service.id}`}
              >
                <Service
                  image={service.image ? url + service.image : require("../../asset/images/Home/300x240-0 1.png")}
                  para={service.name}
                />
              </a>
            </Col>
          ))}
        </Row>
        <div className="d-flex justify-content-center">
          <Paginations currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
        </div>
      </Container>
    </div>
  );
};

export default Services;