import React from 'react'
import "./ServicesType.css"
import { Container, Row, Col } from 'react-bootstrap'
import { Title } from "../../../../Components/index"
import Heart from "../../../../asset/images/svg/heart.svg"
import partners from "../../../../asset/images/svg/partners.svg"
import doctor from "../../../../asset/images/svg/doctor.svg"
import skills from "../../../../asset/images/svg/skills.svg"

const ServicesType = ({ contentData, url }) => {

    const servicesType = contentData.type_of_service || [];

  return (
    <div className='section-margin services-type-content'>
        <img className='services-type-content-bg' src={url + contentData.type_of_service_style} />
        <Container className='section-border'>
            <Row className='services-type-container'>
                <Col lg={4}>
                    <div className='services-type-image'>
                        <img className='services-type-img' src={url + contentData.type_of_service_background} />
                    </div>
                </Col>
                <Col lg={1}></Col>
                <Col lg={7}>
                    <Title allTitle={contentData.type_of_service_title} />
                    <div className='services-type'>
                        {servicesType.map((type, index) => (
                            <div className="service-type">
                                <img className="service-type-icon" src={url + type.image} />
                                <p className="service-type-para">{type.name}</p>
                            </div>
                        ))}
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default ServicesType