import React from 'react'
import "./Doctors.css"
import { Team } from '../../Components'
import { Container, Row, Col } from 'react-bootstrap';

const Doctors = ({ innerPageData, url, contentData }) => {

    const doctorsData = contentData.data || [];

    return (
        <Container className='doctors'>
            <Row>
                {
                    doctorsData.map((item, index) => (
                        <Col md={4} className='doctors-container' key={index}>
                            <div className='doctors-image'>
                                <img className='doctors-img' src={url + item.image} />
                            </div>
                            <h1 className='docotrs-name'>{item.name}</h1>
                            <h2 className='doctors-desc'>{item.description}</h2>
                        </Col>
                    ))
                }
            </Row>
        </Container>
    )
}

export default Doctors
