import React from 'react'
import "./Service.css"

const Service = ( { image, para } ) => {
  return (
    <div className='serve' data-aos="zoom-in" data-aos-duration="1500">
        <div className='serve-img'>
            <img src={image} />
        </div>
        <div className='serve-para'>
            <p>{para}</p>
        </div>
    </div>
  )
}

export default Service